import React from "react";
import _ from "lodash";

import { Layout, SEO, QuoteForm } from "../components";
import { useSeoProps } from "../components/useSeoProps";
import BrightJourneySteps from "../components/BrightJourneySteps";

const QuotePage = () => {
  const metaTitle = "Solicita tu propuesta 100% personalizada sin costo";
  const metaDescription =
    "Solicita una cotización totalmente gratis, ¿Estás listo para hacer el cambio a energía solar?";

  const seoProps = useSeoProps("cotizar", metaTitle, metaDescription);
  return (
    <Layout hideNav={true} hideFooter={true}>
      <SEO {...seoProps} />
      <div className="flex w-full flex-col md:flex-row mx-auto max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="w-full md:w-1/2 py-8 px-8">
          <QuoteForm form="gatsby_cotizar" interactiveProposalVariant={false} />
        </div>
        {/* Don't show the steps if its the channel partner variant of this page,
         *  since that info will be displayed under the channel partner info pane*/}
        <div className="w-full md:w-1/2 flex py-8 lg:py-16 lg:px-8 md:px-0 items-start justify-center ">
          <BrightJourneySteps />
        </div>
      </div>
    </Layout>
  );
};

export default QuotePage;
